<template>
	<!-- 分销商等级 -->
	<div class="dis-level el-content">
		<a-button v-has="{action:'wxapp_marketing_dis_level_add'}" type="primary" @click="showAdd(0)">
			<i class="ri-add-line"></i>添加等级
		</a-button>
		<a-table row-key="id" :data-source="list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'等级名称',dataIndex:'name'},
			{title:'奖金提成(‰)',dataIndex:'level_com'},
			{title:'排序',dataIndex:'rank'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_marketing_dis_level_add'}"  @click="showAdd(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_dis_level_del'}"  @click="deleteLevel(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<a-modal v-model:visible="show.add" title="分销商等级" @ok="saveAddForm" width="600px" @cancel="show.add = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="等级名称">
					<a-input v-model:value="addForm.name"></a-input>
				</a-form-item>
				<a-form-item label="升级条件">
					<a-select v-model:value="addForm.rules" multiple>
						<a-select-option v-for="(item , index) in rule" :key="index" :value="item.id">{{item.title}}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="奖金提成" >
					<a-input v-model:value="addForm.level_com" addon-after="‰"></a-input>
				</a-form-item>
				<a-form-item>
					<template #label>
						<span>计算周期</span>
						<a-tooltip title="1、升级条件：计算周期内满足升级要求 2、降级条件：计算周期内低于当前身份要求">
							<i class="ri-information-fill"></i>
						</a-tooltip>
					</template>
					<a-input v-model:value="addForm.cycle_day" addon-after="日以内"></a-input>
				</a-form-item>
				<a-form-item label="排序" >
					<a-input v-model:value="addForm.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>

	</div>
	
</template>

<script>
import { reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			list:[],
			show:{ add:false, },
			addForm:null,
			rule:[],
		})
		getDisLevel()
		distributionModel.getDisRule(1,999,res=>state.rule = res.list)

		function getDisLevel(){
			distributionModel.getDisLevel(res=>state.list = res)
		}

		function showAdd(row){
			state.addForm = {
				id:row?row.id :0,
				name:row?row.name :"",
				rank:row?row.rank :99,
				level_com:row?row.level_com :"",
				cycle_day:row?row.cycle_day :"",
				rules:row && row.rules? JSON.parse(row.rules) :[],
			}
			state.show.add = true
		}

		const saveAddForm =()=>distributionModel.addOrEditLevel(state.addForm,()=>{
			getDisLevel()
			state.show.add = false
		})
		const deleteLevel = (id)=>commonModel.deleteDataList(id,43,"确认删除分销商等级吗？").then(()=>getDisLevel())

		return{
			...toRefs(state),
			getDisLevel,
			saveAddForm,
			showAdd,
			deleteLevel
		}
	},
	
}
</script>

<style lang="scss">
</style>
